import React, { useEffect, useState } from 'react';
import { getApiKeys, deleteApiKey } from '../services/api';
import { Table, Button, Alert } from 'react-bootstrap';
import { FaEye, FaEyeSlash, FaCopy } from 'react-icons/fa'; // Importing icons
import copy from 'copy-to-clipboard'; // Copy to clipboard function

const ApiKeyList = () => {
    const [apiKeys, setApiKeys] = useState([]);
    const [message, setMessage] = useState({});
    const [visibility, setVisibility] = useState({});

    useEffect(() => {
        // Fetch the API keys when the component loads
        const fetchApiKeys = async () => {
            const token = localStorage.getItem('adminToken');
            try {
                const response = await getApiKeys(token);
                setApiKeys(response.data.data);
            } catch (error) {
                setMessage({
                    type: 'error',
                    text: 'Error fetching API keys: ' + error.response.data.message,
                });
            }
        };

        fetchApiKeys();
    }, []);

    const handleDelete = async (apiKeyId) => {
        const token = localStorage.getItem('adminToken');
        try {
            await deleteApiKey(token, apiKeyId);
            setMessage({
                type: 'success',
                text: 'API key deleted successfully!',
            });
            // Update the list after deletion
            setApiKeys(apiKeys.filter(apiKey => apiKey.id !== apiKeyId));
        } catch (error) {
            setMessage({
                type: 'error',
                text: 'Error deleting API key: ' + error.response.data.message,
            });
        }
    };

    const toggleVisibility = (id) => {
        setVisibility((prevState) => ({
            ...prevState,
            [id]: !prevState[id], // Toggle visibility for the given API key
        }));
    };

    const handleCopy = (apiKey) => {
        copy(apiKey);
        setMessage({
            type: 'info',
            text: 'API key copied to clipboard!',
        });
    };

    return (
        <div className="container mt-5">
            <h2>API Key List</h2>
            {message.text && <Alert variant={message.type === 'error' ? 'danger' : 'info'}>{message.text}</Alert>}
            
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Api-Key</th>
                        <th>Expiry (Days)</th>
                        <th>Expired</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {apiKeys.map((apiKey) => (
                        <tr key={apiKey.id}>
                            <td>{apiKey.username}</td>
                            <td>{apiKey.email}</td>
                            <td>
                                {/* Show either the full API key or masked version */}
                                {visibility[apiKey.id] ? apiKey.api_key : 'xxxxxxxxxxxxx'}
                                
                                {/* Toggle visibility icon */}
                                <Button
                                    variant="link"
                                    onClick={() => toggleVisibility(apiKey.id)}
                                >
                                    {visibility[apiKey.id] ? <FaEyeSlash /> : <FaEye />}
                                </Button>

                                {/* Copy icon */}
                                <Button
                                    variant="link"
                                    onClick={() => handleCopy(apiKey.api_key)}
                                >
                                    <FaCopy />
                                </Button>
                            </td>
                            <td>{apiKey.expiry}</td>
                            <td>{apiKey.expired ? 'Yes' : 'No'}</td>
                            <td>
                                <Button
                                    variant="danger"
                                    onClick={() => handleDelete(apiKey.id)}
                                >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default ApiKeyList;
