import React, { useEffect, useState } from 'react';
import { generateApiKey } from '../services/api';
import { Form, Button, Alert } from 'react-bootstrap';

const ApiKeyGenerator = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [expiry, setExpiry] = useState('');
    const [customDate, setCustomDate] = useState('');
    const [message, setMessage] = useState('');
    const [expiredDate, setexpiredDate] = useState('')

    const today = new Date();
    const todayDate = today.toISOString().split('T')[0]; // Format today’s date for date input

    const handleGenerate = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('adminToken'); // Get token from storage

        // Calculate the expiry date if a custom date is not set
        let expiryDate;
        if (expiry === 'custom') {
            expiryDate = customDate;
        } else {
            const days = parseInt(expiry, 10);
            const futureDate = new Date();
            futureDate.setDate(today.getDate() + days);
            expiryDate = futureDate.toISOString().split('T')[0];
        }

        try {
            await generateApiKey(token, username, email, expiryDate);
            setMessage('API key generated successfully!');
        } catch (error) {
            setMessage('Error generating API key: ' + error.response.data.message);
        }
    };

    useEffect(()=>{
        if (expiry != "custom" && expiry != ""){
            const days = parseInt(expiry, 10);
            const futureDate = new Date();
            futureDate.setDate(today.getDate() + days);
            let expiryDate = futureDate.toISOString().split('T')[0];
            setexpiredDate(expiryDate)
        }else{
            setexpiredDate('')
        }
    }, [expiry])

    return (
        <div className="container mt-5">
            <h2>Generate API Key</h2>
            {message && <Alert variant="info">{message}</Alert>}
            <Form onSubmit={handleGenerate}>
                <Form.Group controlId="formUsername">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId="formExpiry">
                    <Form.Label>Expiry   {(expiry != "custom" && expiry != "") ? `( Expired at ${expiredDate} )`: ""}</Form.Label>
                    <Form.Control
                        as="select"
                        value={expiry}
                        onChange={(e) => {
                            const selectedValue = e.target.value;
                            setExpiry(selectedValue);
                            if (selectedValue === 'custom') {
                                setCustomDate(todayDate); // Set default custom date to today
                            }
                        }}
                    >
                        <option value="">Select expiry</option>
                        <option value="7">7 days</option>
                        <option value="30">30 days</option>
                        <option value="60">60 days</option>
                        <option value="90">90 days</option>
                        <option value="custom">Custom</option>
                    </Form.Control>

                    {expiry === 'custom' && (
                        <Form.Control
                            type="date"
                            placeholder="Select custom expiry date"
                            value={customDate}
                            min={todayDate}
                            onChange={(e) => setCustomDate(e.target.value)}
                        />
                    )}
                </Form.Group>

                <Button variant="primary" type="submit">Generate API Key</Button>
            </Form>
        </div>
    );
};

export default ApiKeyGenerator;
