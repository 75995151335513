import axios from 'axios';

const API_BASE_URL = 'https://mlstudio.teaminnovatics.com';
// const API_BASE_URL = 'http://127.0.0.1:8000';


// Function to handle login
export const login = async (username, password) => {
    return await axios.post(`${API_BASE_URL}/login`, {username, password });
};

// Function to update password
export const updatePassword = async (token, currentPassword, newPassword) => {
    return await axios.put(`${API_BASE_URL}/update-password`, {
        "currentPassword": currentPassword,
        "newPassword": newPassword,
    }, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Function to generate API key
export const generateApiKey = async (token, username, email, expiry) => {
    return await axios.post(`${API_BASE_URL}/generate-key`, {
        "username": username,
        "email": email,
        "expiry": expiry,
    }, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Function to get list of API keys
export const getApiKeys = async (token) => {
    return await axios.get(`${API_BASE_URL}/api-keys`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Function to delete an API key
export const deleteApiKey = async (token, apiKeyId) => {
    return await axios.delete(`${API_BASE_URL}/api-keys/${apiKeyId}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

alert()