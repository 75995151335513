import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';  // Use 'Routes' instead of 'Switch'
import AdminLogin from './components/AdminLogin';
import ApiKeyGenerator from './components/ApiKeyGenerator';
import ApiKeyList from './components/ApiKeyList';
import Header from './components/Header';
import UpdatePassword from './components/UpdatePassword';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
    const [token, setToken] = useState(localStorage.getItem('adminToken') || null);

    return ( 
        <Router>
            <Header />
            <Routes>
                <Route path="/generate-key" element={token ? <ApiKeyGenerator /> : <AdminLogin setToken={setToken} />} />
                <Route path="/api-keys" element={token ? <ApiKeyList /> : <AdminLogin setToken={setToken} />} />
                <Route path="/change-password" element={token ? <UpdatePassword /> : <AdminLogin setToken={setToken} />} />
                <Route path="/" element={<AdminLogin setToken={setToken} />} />
            </Routes>
        </Router>
    );
};

export default App;
