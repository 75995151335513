import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login, updatePassword } from '../services/api';
import { Form, Button, Alert } from 'react-bootstrap';

const AdminLogin = ({ setToken }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await login(username, password);
            console.log(response)
            setToken(response.data.data); // Save the token for later use
            localStorage.setItem('adminToken', response.data.data); // Save token in localStorage
            setMessage('Login successful!');
        } catch (error) {
            setMessage('Login failed: ' + error.response.data.data.message);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('adminToken');
        if (token != null || token == '') {
            navigate('/generate-key');
        }
    }, [])

    return (
        <div className="container mt-5">
            <h2>{'Admin Login'}</h2>
            {message && <Alert variant="info">{message}</Alert>}
            <Form onSubmit={handleLogin}>
                <Form.Group controlId="formUsername">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId="formPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Enter password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>

                <Button variant="primary" type="submit">Login</Button>
            </Form>
        </div>
    );
};

export default AdminLogin;
