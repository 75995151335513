import React, { useState } from 'react';
import { updatePassword } from '../services/api';
import { Form, Button, Alert } from 'react-bootstrap';

const UpdatePassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [reEnterNewPassword, setReEnterNewPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleUpdatePassword = async (e) => {
        e.preventDefault();
        if (newPassword !== reEnterNewPassword) {
            setMessage("Passwords do not match!");
            return;
        }

        const token = localStorage.getItem('adminToken');
        try {
            await updatePassword(token, currentPassword, newPassword);
            setMessage('Password updated successfully!');
        } catch (error) {
            setMessage('Password update failed: ' + error.response.data.message);
        }
    };

    return (
        <div className="container mt-5">
            <h2>{'Update Password'}</h2>
            {message && <Alert variant="info">{message}</Alert>}
                <Form onSubmit={handleUpdatePassword}>
                    <Form.Group controlId="formCurrentPassword">
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Enter current password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="formNewPassword">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="formReEnterNewPassword">
                        <Form.Label>Re-enter New Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Re-enter new password"
                            value={reEnterNewPassword}
                            onChange={(e) => setReEnterNewPassword(e.target.value)}
                        />
                    </Form.Group>

                    <Button variant="primary" type="submit">Update Password</Button>
                </Form>
        </div>
    );
};

export default UpdatePassword;
